<template>
  <b-container class="mt-5 position-relative" fluid>
    <div class="new-badge d-xl-flex d-none">
      <p class="heading f-p2">{{ $t("banner.now") }}</p>
      <p class="f-p4">{{ $t("banner.eachOrder") }}</p>
    </div>
    <b-row class="banner-top">
      <b-col cols="5" sm="7" md="8" lg="6" align-self="center" order-xl="2">
        <!-- Desktop -->
        <ol class="d-xl-block d-none my-0 f-p2">
          <li>
            {{ $t("banner.steps_1") }}
          </li>
          <li>
            {{ $t("banner.steps_2") }}
          </li>
          <li>
            {{ $t("banner.steps_3") }}
          </li>
        </ol>
        <!-- Mobile -->
        <div class="d-xl-none f-h3 heading">
          {{ $t("banner.appointment") }}
        </div>
        <div class="d-xl-none f-p3">{{ $t("banner.eachOrderExclusiv") }}</div>
      </b-col>
      <b-col
        cols="3"
        sm="3"
        md="4"
        lg="3"
        class="trust-badge d-flex"
        order-xl="3"
      >
        <b-img src="@/assets/medical-beirat-white.png" class="w-100" />
      </b-col>
      <b-col
        cols="4"
        sm="2"
        lg="3"
        class="d-lg-block d-md-none person"
        order-xl="1"
      >
        <b-img src="@/assets/hp.webp" class="person-img w-100 h-100" />
      </b-col>
    </b-row>
    <b-row class="banner-bottom d-xl-block d-none">
      <b-col class="heading f-p2 p-0">
        {{ $t("banner.eachAppointmentOrder") }}
        <div class="f-p4 pt-1">
          {{ $t("banner.eachAppointmentOrder.info") }}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ProductDetailAppointmentBanner",
  i18nOptions: { keyPrefix: "productDetail" },
};
</script>

<style lang="scss" scoped>
.new-badge {
  background-color: var(--zimply-light-green);
  color: var(--zimply-beige);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  position: absolute;
  right: -2.5rem;
  top: -2.5rem;

  @media only screen and (max-width: 1400px) {
    right: -10px;
  }

  p {
    margin-bottom: 0;
  }
}

.banner-top {
  background-color: var(--zimply-dark-green);
  color: var(--zimply-beige);
  border-radius: 10px;
  padding: 15px 20px;

  @media only screen and (max-width: 1200px) {
    padding-right: 0;
  }

  .trust-badge {
    align-items: start;
  }

  @media only screen and (min-width: 1200px) {
    .trust-badge {
      align-items: end;
    }
  }

  .person {
    margin-left: -20px;
    margin-bottom: -15px;
    margin-top: -30px;
    padding-left: 0;

    img {
      object-fit: cover;
    }

    @media only screen and (max-width: 1200px) {
      margin-left: 0;
      padding-right: 0px;

      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
  }
}

.banner-bottom {
  background-color: var(--zimply-beige);
  color: var(--zimply-black);
  border-radius: 10px;
  margin-top: -20px;
  padding: 30px 10px 10px 10px;
  z-index: -1;
  position: relative;
}

.heading div {
  font-weight: 400;
}
</style>
