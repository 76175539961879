<template>
  <div class="mixture-wrapper">
    <b-container fluid="xxl" class="mixture-content-wrapper f-p1 mb-5">
      <!-- Product -->
      <ProductDetailHeader
        :product="product"
        :hasInput="hasInput"
        class="d-md-none"
      />
      <b-row v-if="hasInput && !product.recipe.num">
        <div class="text-center mb-5 px-4 missing-mixture f-p1">
          <p>{{ $t("errorMissingMixture_1") }}</p>
          <p>{{ $t("errorMissingMixture_2") }}</p>
          <p>{{ $t("errorMissingMixture_3") }}</p>
          <b-button @click.prevent="go_start()" variant="green">
            {{ $t("errorMissingMixture_Button") }}
          </b-button>
        </div>
      </b-row>
      <b-row v-else>
        <b-col cols="12" md="6">
          <ProductDetailImageCarousel
            :product="product"
            :firstname="firstname"
            :vegan="vegan"
            @share-action="showSendMixture"
          />
        </b-col>
        <b-col cols="12" md="6">
          <ProductDetailHeader
            :product="product"
            :hasInput="hasInput"
            class="d-none d-md-block"
          />
          <ProductDetailKeySymptoms :symptoms="product.details.symptoms" />
          <ProductDetailIngredientsSmall
            v-if="product.recipe"
            :ingredients="product.recipe.content"
            amount="6"
            md_amount="4"
            @all-ingredients="scrollToIngredients"
          />
          <!-- Checkout Controls -->
          <div class="checkout">
            <b-row>
              <b-col cols="7">
                <div class="heading f-h1">
                  <template v-if="product.details.strikeprice">
                    <span class="strikeprice">{{
                      formatCurrency(product.details.strikeprice)
                    }}</span>
                    <span class="font-weight-normal f-p4">{{
                      $t("strikeprice")
                    }}</span>
                  </template>
                  {{ formatCurrency(product.price) }}
                </div>
                <div class="f-p4">
                  {{ $t("taxInfo")
                  }}<template v-if="sprayBasePrice"
                    >, {{ sprayBasePrice }}</template
                  >
                </div>
              </b-col>
              <b-col cols="5" class="text-end">
                <span class="heading f-h2"
                  >{{ this.formatNumber(this.$znc_stats.ts_stars)
                  }}<span style="font-weight: 400"
                    >/<span class="f-p2">5</span>
                  </span>
                </span>
                <BIconStarFill class="f-h2 ml-2 star" />
              </b-col>
            </b-row>
            <b-row class="py-3">
              <b-col cols="12" class="f-p2 d-flex align-items-center">
                <span class="mr-4 heading">{{ $t("quantity") }}</span>
                <b-form-spinbutton
                  size="sm"
                  v-model="quantitySelected"
                  :min="1"
                  :max="10"
                  inline
                  class="quantity-selecter"
                />
              </b-col>
            </b-row>
            <b-row class="checkout-buttons">
              <b-col cols="12" lg="6">
                <b-button
                  @click="checkoutProductClick()"
                  href="#"
                  variant="primary"
                  class="f-p1 cart-button"
                >
                  {{ $t("buttonCheckout") }}
                </b-button>
                <div class="f-p4 pt-1">
                  {{ $t("shippingInfo") }}
                  <b-link :href="$t('shippingInfo.link')" target="_blank">{{
                    $t("infoLink")
                  }}</b-link>
                </div>
              </b-col>
              <b-col
                cols="12"
                class="payment-alt text-center d-lg-none d-flex f-p3 my-2"
              >
                <hr class="separator" />
                {{ $t("paymentAlt") }}
                <hr class="separator" />
              </b-col>
              <b-col cols="12" lg="6">
                <div v-if="checkoutState.error" class="error pb-1 f-p3">
                  <span v-html="checkoutState.message" />
                </div>
                <div v-if="checkoutState.running" class="f-p3">
                  <b-spinner small></b-spinner> {{ checkoutState.message }}
                </div>
                <div
                  id="paypal-express-pd"
                  :class="checkoutState.running ? 'd-none' : ''"
                ></div>
                <div class="f-p4 pt-1">
                  <i18next :translation="$t('expressDesc')">
                    <template #termsOfUseLink>
                      <b-link
                        target="_blank"
                        tabindex="-1"
                        class="text-link"
                        :href="$t('termsURL')"
                        >{{ $t("terms") }}</b-link
                      >
                    </template>
                    <template #cancellationLink>
                      <b-link
                        target="_blank"
                        tabindex="-1"
                        class="text-link"
                        :href="$t('cancellationURL')"
                        >{{ $t("cancellation") }}</b-link
                      >
                    </template>
                    <template #privacyLink>
                      <b-link
                        target="_blank"
                        tabindex="-1"
                        class="text-link"
                        :href="$t('privacyURL')"
                        >{{ $t("privacy") }}</b-link
                      >
                    </template>
                  </i18next>
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- Checkout Controls -->
          <ProductDetailAppointmentBanner />
        </b-col>
      </b-row>
    </b-container>
    <ProductDetailGeneralFacts :mode="product.recipe ? 'spray' : 'general'" />
    <ProductDetailCustomerReviews :reviews="product.details.reviews" />
    <b-container fluid="xxl" class="my-5 exit-intent-anchor" id="info-tabs">
      <!-- Tabs -->
      <b-tabs
        pills
        lazy
        nav-class="info-tab"
        content-class="info-tab-content"
        v-model="infoTabIndex"
      >
        <b-tab
          :title="$t('tab.productDescription')"
          active
          v-if="product.details.desc"
        >
          <InfoTabText :title="$t('tab.productDescription')">
            {{ product.details.desc }}
          </InfoTabText>
        </b-tab>
        <b-tab v-if="product.recipe" :title="$t('ingredients')">
          <InfoTabIngredients
            :title="$t('ingredients')"
            :ingredients="product.recipe.content"
          />
        </b-tab>
        <b-tab v-if="product.recipe" :title="$t('tab.production')">
          <InfoTabText :title="$t('tab.production')" img="herstellung.jpg">
            <i18next :translation="$t('productionDesc')">
              <template #lineBreak>
                <br />
              </template>
            </i18next>
          </InfoTabText>
        </b-tab>
        <b-tab :title="product.recipe ? $t('tab.hints') : $t('tab.hints2')">
          <InfoTabText :title="$t('tab.hints')">
            <div v-html="product.details.hints" />
          </InfoTabText>
        </b-tab>
        <b-tab :title="$t('tab.faq')">
          <InfoTabAccordion :title="$t('tab.faq')" :accordion="faq" />
        </b-tab>
      </b-tabs>
    </b-container>
    <ProductDetailAdvisors />
    <ProductDetailZimplyGeneral />
    <ProductDetailPdfCheckout
      v-if="product.alternative"
      :product="product.alternative"
      @checkout-action="checkoutAlternative"
    />

    <ModalQuantitySelection
      @checkout_spray="checkoutSpray"
      @checkout_subscription="showNewSubscription"
      :price="product.price"
    ></ModalQuantitySelection>
    <ModalNewSubscription
      @checkout_subscription="checkoutSpraySubscription"
      :product="product"
      :firstname="firstname"
      :ok_title="$t('buttonSubscription')"
    />
    <ModalExitIntent></ModalExitIntent>
    <ModalSendMixture
      v-if="product.recipe"
      :mixture_num="product.recipe.num"
      :firstname="firstname"
      :partner="$order_info.partner"
    ></ModalSendMixture>
  </div>
</template>

<script>
import { BIconStarFill } from "bootstrap-vue";

import ModalExitIntent from "@/components/ModalExitIntent.vue";
import ModalQuantitySelection from "@/components/ModalQuantitySelection.vue";
import ModalNewSubscription from "@/components/ModalNewSubscription.vue";
import ModalSendMixture from "@/components/ModalSendMixture.vue";

import ProductDetailHeader from "@/components/ProductDetail/ProductDetailHeader.vue";
import ProductDetailImageCarousel from "@/components/ProductDetail/ProductDetailImageCarousel.vue";
import ProductDetailKeySymptoms from "@/components/ProductDetail/ProductDetailKeySymptoms.vue";
import ProductDetailIngredientsSmall from "@/components/ProductDetail/ProductDetailIngredientsSmall.vue";
import ProductDetailAppointmentBanner from "@/components/ProductDetail/ProductDetailAppointmentBanner.vue";
import ProductDetailGeneralFacts from "@/components/ProductDetail/ProductDetailGeneralFacts.vue";
import ProductDetailCustomerReviews from "@/components/ProductDetail/ProductDetailCustomerReviews.vue";
import ProductDetailAdvisors from "@/components/ProductDetail/ProductDetailAdvisors.vue";
import ProductDetailZimplyGeneral from "@/components/ProductDetail/ProductDetailZimplyGeneral.vue";
import ProductDetailPdfCheckout from "@/components/ProductDetail/ProductDetailPdfCheckout.vue";

import InfoTabText from "@/components/ProductDetail/Tabs/InfoTabText.vue";
import InfoTabAccordion from "@/components/ProductDetail/Tabs/InfoTabAccordion.vue";
import InfoTabIngredients from "@/components/ProductDetail/Tabs/InfoTabIngredients.vue";

export default {
  name: "ProductDetail",
  components: {
    BIconStarFill,
    ModalExitIntent,
    ModalQuantitySelection,
    ModalNewSubscription,
    ModalSendMixture,
    ProductDetailHeader,
    ProductDetailImageCarousel,
    ProductDetailKeySymptoms,
    ProductDetailIngredientsSmall,
    ProductDetailAppointmentBanner,
    ProductDetailGeneralFacts,
    ProductDetailCustomerReviews,
    ProductDetailAdvisors,
    ProductDetailZimplyGeneral,
    ProductDetailPdfCheckout,
    InfoTabText,
    InfoTabAccordion,
    InfoTabIngredients,
  },
  props: ["product", "firstname", "hasInput"],
  i18nOptions: { keyPrefix: "productDetail" },
  metaInfo: function () {
    let rtn = {
      title:
        this.product.type == "Spray"
          ? this.product.recipe.num
          : this.product.details.name ?? this.product.num,
      meta: [
        {
          property: "og:title",
          content:
            this.product.type == "Spray"
              ? this.product.recipe.num
              : this.product.details.name ?? this.product.num,
        },
        { name: "description", content: this.product.details.metadesc },
        {
          property: "og:description",
          content: this.product.details.metadesc,
        },
        { property: "og:url", content: window.location.href },
      ],
    };
    if (this.product.details.images && this.product.details.images.length > 0) {
      rtn.meta.push({
        property: "og:image",
        content: this.product.details.images[0],
      });
    }
    return rtn;
  },
  data: function () {
    return {
      exitIntentAnchorWasVisible: false,
      exitIntentAnchorVisibleTime: null,
      exitIntentAlreadyShown: false,
      exitIntentWaitSec: 15,
      quantitySelected: 1,
      quantityMode: "quantity-selection",
      checkoutState: {
        error: false,
        message: null,
        running: false,
      },
      checkoutData: {
        paymentInfo: {
          provider: "PayPal",
          method: "paypal-express-p",
        },
        pharmacy_id: this.$pharmacy.id,
      },
      infoTabIndex: 0,
    };
  },
  mounted() {
    this.$EventBus.$emit("execute_abtest", "Quantity_Selection", [
      {
        name: "original",
        activate: () => {},
      },
      {
        name: "Upgrade",
        activate: () => {
          this.quantityMode = "quantity-upgrade";
        },
      },
      {
        name: "CartOnly",
        activate: () => {
          this.quantityMode = "cart-only";
        },
      },
    ]);

    let products = [
      {
        id: this.product.num,
        price: this.product.price,
        type: this.product.type,
        branch: this.product.indication,
        mixture: this.product.mixture,
      },
    ];
    this.$EventBus.$emit("gtag_ecom", {
      event: "viewItem",
      ecommerce: {
        currencyCode: "EUR",
        viewItem: {
          products: products,
        },
      },
      cart: products,
    });

    setTimeout(() => {
      document.documentElement.addEventListener(
        "mouseleave",
        this.exitIntentEventListener
      );
    }, 5000);

    if (this.$isMobile) {
      let observerIn = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === true) {
            this.exitIntentAnchorWasVisible = true;
            this.exitIntentAnchorVisibleTime = new Date().getTime();
          }
        },
        { threshold: [0.1] }
      );
      observerIn.observe(document.querySelector(".exit-intent-anchor"));

      let observerOut = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting === false) {
            if (
              this.exitIntentAnchorWasVisible &&
              this.exitIntentAnchorVisibleTime <
                new Date().getTime() - this.exitIntentWaitSec * 1000
            ) {
              setTimeout(() => {
                this.showExitIntentPopup();
              }, 600);
            } else {
              this.exitIntentAnchorWasVisible = false;
            }
          }
        },
        { threshold: [0.1] }
      );
      observerOut.observe(document.querySelector(".exit-intent-anchor"));
    }

    this.loadPayPal().then(() => {
      this.enablePayPalCheckout();
    });
  },
  destroyed() {
    document.documentElement.removeEventListener(
      "mouseleave",
      this.exitIntentEventListener
    );
  },
  computed: {
    sprayBasePrice: function () {
      return this.calcBasePrice(this.product);
    },
    vegan: function () {
      if (!this.product.recipe) return false;
      var non_vegan_ingredients = this.product.recipe.content.filter(function (
        elem
      ) {
        if (
          elem.name.includes("Euspongia officinalis") ||
          elem.name.includes("Propolis") ||
          elem.name.includes("Apis mellifica")
        )
          return elem.Value;
      });

      return non_vegan_ingredients.length <= 0;
    },
    faq: function () {
      let faqs = this.$t("faq").split("\n\n");
      let res = [];
      faqs.forEach((elem) => {
        let tmp = elem.split("\n");
        res.push({
          heading: tmp[0],
          text: tmp.splice(1).join("{lineBreak}"),
        });
      });
      return res;
    },
  },
  methods: {
    randomNumber: function (min, max) {
      let range = max - min;
      let rand = Math.floor(Math.random() * Math.floor(range));
      return rand + min;
    },
    loadPayPal: function () {
      return this._loadPayPal(false);
    },
    enablePayPalCheckout: async function () {
      /*global paypal */
      paypal
        .Buttons({
          style: {
            layout: "vertical",
            color: "gold",
            shape: "rect",
            height: this.$isMobile ? 34 : 40,
            label: "buynow",
          },
          onClick: async (data, actions) => {
            this.buildCheckoutData();
            this.setCheckoutStateRunning();
            if (this.checkoutState.error) {
              return actions.reject();
            }
            this.checkoutState.message = this.$t("messageConfirmPayPal");
            return actions.resolve();
          },
          createOrder: () => {
            return this.preparePayment();
          },
          onError: () => {
            this.setCheckoutStateError(this.$t("errorPaymentExecution"));
          },
          onCancel: () => {
            this.resetCheckoutState();
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              this.checkoutState.message = this.$t("messageProcessing");
              try {
                this.checkoutData.paymentInfo.orderID = details.id;
                this.checkoutData.paymentInfo.id =
                  details.purchase_units[0].payments.captures[0].id;
              } catch (e) {
                // ignore
              }
              this.finishCheckout();
            });
          },
          onShippingChange: async (data, actions) => {
            if (
              !this.validCountry().includes(data.shipping_address.country_code)
            ) {
              this.logMessageOnServer(
                "ERROR: Reject PayPal payment for country " +
                  data.shipping_address.country_code
              );
              return actions.reject();
            }
            this.checkoutData.paymentInfo.orderID = data.orderID;
            this.checkoutData.billing = {
              country: {
                code: data.shipping_address.country_code,
              },
            };
            let result = await fetch(
              this.$ZNC_BACKEND_BASE + "/business/v1/update-payment",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(this.checkoutData),
              }
            );
            if (result.ok) {
              return actions.resolve();
            } else {
              this.logMessageOnServer(
                "ERROR: Reject PayPal payment after error"
              );
              return actions.reject();
            }
          },
        })
        .render("#paypal-express-pd");
    },
    preparePayment: function () {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/prepare-payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.checkoutData),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              if (result.orderId) {
                this.checkoutData.orderId = result.orderId;
              }
              resolve(result.id);
            } else {
              resolve(false);
            }
          });
      });
    },
    finishCheckout: function () {
      this._finishCheckout(this.checkoutData).then((result) => {
        if (result) {
          this.resetCheckoutState();
          this.$router.push("/thankyou");
        } else {
          this.setCheckoutStateError(this.$t("errorStore"));
        }
      });
    },
    buildCheckoutData: function () {
      let product = this.product;
      let discountp = this.getQuantityDiscount(this.quantitySelected);
      let price = this.round(product.price - (product.price * discountp) / 100);
      let brutto = price * this.quantitySelected;
      this.checkoutData.cart = [
        {
          product: product,
          id: product.num,
          type: product.type,
          name: product.details.name,
          firstname: this.firstname,
          deliverytime: product.details.deliverytime,
          count: this.quantitySelected,
          price: price,
          sum: brutto,
          code: product.code,
          branch: product.indication,
          mixture: product.mixture,
          discountp: discountp,
        },
      ];
      this.checkoutData.cartSummary = {
        sum: this.round(brutto),
        netto: this.round(brutto / 1.19),
        tax: this.round(brutto - brutto / 1.19),
      };
      if (!this.checkoutData.uuid) {
        this.checkoutData.uuid = this.generateUuid();
      }
      this.checkoutData.partner = this.$order_info.partner;
      this.checkoutData.lang = this.$i18next.resolvedLanguage;
      return this.checkoutData;
    },
    resetCheckoutState: function () {
      this.checkoutState.running = false;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateRunning: function () {
      this.checkoutState.running = true;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateError: function (message) {
      this.checkoutState.running = false;
      this.checkoutState.error = true;
      this.checkoutState.message = message;
    },
    checkoutProductClick: function () {
      let isSpray =
        this.product.type == "Spray" ||
        this.product.type == "Standard-Mischung";
      if (this.quantitySelected > 1 || !isSpray) {
        this.quantityMode = "cart-only";
        this.checkoutSpray(this.quantitySelected);
      } else {
        if (this.quantityMode == "cart-only") {
          this.checkoutSpray(1);
        } else if (this.quantityMode == "quantity-upgrade") {
          this.checkoutSpray(1);
        } else {
          this.showQuantitySelection();
        }
      }
    },
    showQuantitySelection: function () {
      this.$bvModal.show("modal-quantityselection");
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.product.recipe.indication,
        schritt: "mengenwahl",
      });
    },
    showNewSubscription: function () {
      this.$bvModal.hide("modal-quantityselection");
      this.$bvModal.show("modal-new-subscription");
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.product.recipe.indication,
        schritt: "abonnement",
      });
    },
    showSendMixture: function () {
      this.$bvModal.show("modal-sendmixture");
    },
    exitIntentEventListener: function (e) {
      if (e.clientY <= 20) {
        this.showExitIntentPopup();
      }
    },
    showExitIntentPopup: function () {
      if (!this.exitIntentAlreadyShown) {
        let event_info = "";
        if (this.$isMobile) {
          this.$bvModal.show("exit-intent-m");
          event_info = "mobile";
        } else {
          this.$bvModal.show("exit-intent-d");
          event_info = "desktop";
        }
        this.exitIntentAlreadyShown = true;
        this.$EventBus.$emit("gtag", {
          event: "konfigurator-checkout",
          indikation: this.product.recipe
            ? this.product.recipe.indication
            : null,
          schritt: "exit-intent",
          teilschritt: event_info,
        });
      }
    },
    go_start: function () {
      this.$EventBus.$emit("go_start");
    },
    checkoutSpray: function (quantity) {
      this.checkoutProduct(this.product, quantity);
    },
    checkoutAlternative: function () {
      this.checkoutProduct(this.product.alternative, 1);
    },
    checkoutSpraySubscription: function (quantity, interval) {
      this.checkoutProduct(this.product, quantity, interval);
    },
    checkoutProduct: function (
      product,
      quantity,
      subscription_interval = null
    ) {
      this.$EventBus.$emit(
        "checkout_product",
        product,
        quantity,
        subscription_interval,
        this.firstname
      );
      this.$router.push({
        name: "Checkout",
        params: {
          quantityMode: this.quantityMode,
        },
      });
    },
    calcBasePrice: function (product) {
      if (product.details.volume) {
        return (
          this.formatNumber((product.price / product.details.volume) * 1000) +
          " €/l"
        );
      }
      return null;
    },
    scrollToIngredients: function () {
      const tabElement = document.getElementById("info-tabs");
      tabElement.scrollIntoView({ behavior: "smooth" });
      this.infoTabIndex = this.product.details.desc ? "1" : "0";
    },
  },
};
</script>

<style lang="scss">
.footnotes {
  text-align: left;
  a {
    color: black;
    text-decoration: underline;
  }
}
.wait-spinner {
  width: 3rem;
  height: 3rem;
}

.mixture-wrapper {
  position: relative;
}
.heading {
  font-weight: 700;
  font-family: var(--text-montserrat);
  text-align: left;
}
.strikeprice {
  text-decoration: line-through;
  color: var(--red);
}

.missing-mixture {
  padding-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: black;
  max-width: 500px;
}

.text-end {
  text-align: end;
}

.star {
  color: var(--star-yellow);
}

.checkout {
  .quantity-selecter {
    border-radius: 10px;
    border-color: var(--zimply-black);

    output {
      border-inline: none !important;
      padding: 0;
    }
  }

  .checkout-buttons {
    .cart-button {
      width: 100%;
    }

    #paypal-express-pd {
      width: 100%;
      height: 41.5px;
    }

    @media only screen and (max-width: 768px) {
      #paypal-express-pd {
        height: 36.5px;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .payment-alt {
      align-items: center;
      max-width: 100%;

      .separator {
        border-color: var(--zimply-black);
        flex-grow: 1;
        margin-inline: 30px;
      }
    }
  }
}

.info-tab {
  gap: 5px;
  row-gap: 10px;

  @media only screen and (min-width: 768px) {
    gap: 10px;
  }

  .nav-link {
    background-color: var(--zimply-lighter-green);
    color: var(--zimply-black);
    font-family: var(--text-montserrat);
    font-size: 0.9375rem;
    border-radius: 10px;
    padding: 0.25rem 0.5rem;

    &.active {
      background-color: var(--zimply-dark-green);
      color: var(--zimply-beige);
    }

    @media only screen and (min-width: 768px) {
      font-size: 1.1875rem;
      padding: 0.25rem 0.75rem;
    }
  }
}

.info-tab-content {
  margin-block: 15px;
}

.arrow-link {
  color: var(--zimply-black);
}

.report-img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}
</style>
