<template>
  <div class="checkout-wrapper">
    <div class="header banner">
      <h1>{{ $t("headline") }}</h1>
    </div>
    <div class="checkout-content-wrapper my-5 container-xxl">
      <div v-if="product_unknown" class="error pb-2">
        {{ $t("unknownProduct") }}
      </div>
      <div class="content f-p3">
        <b-table-lite
          class="cart-table"
          stacked="sm"
          striped
          hover
          :items="$cart"
          :fields="cartFields"
        >
          <template #cell(count)="data">
            <b-form-spinbutton
              v-model="data.value"
              @change="updateCart(data.index, data.value)"
              min="0"
              :max="data.item.type == 'Mischanweisung' ? 1 : 10"
              inline
            >
              <template #decrement="s">
                <BIconDash
                  v-if="data.value >= 1"
                  aria-hidden="true"
                  :scale="s.hasFocus ? 1.5 : 1.25"
                />
                <BIconCartX
                  v-if="data.value < 1"
                  aria-hidden="true"
                  :scale="s.hasFocus ? 1.15 : 1.0"
                />
              </template>
            </b-form-spinbutton>
          </template>
          <template #cell(price)="data">
            <div
              v-if="data.item.product.price != data.item.price"
              class="price-org"
            >
              {{ formatCurrency(data.item.product.price) }}
            </div>
            <span>{{ data.value }}</span>
            <div v-if="data.item.baseprice" class="f-p4">
              ({{ data.item.baseprice }})
            </div>
          </template>
          <template #cell(name)="data">
            <div>{{ data.value }}</div>
            <span v-if="data.item.deliverytime >= 0" class="f-p4">
              {{ $t("deliveryTime", { count: data.item.deliverytime }) }};
            </span>
            <b-link
              v-if="
                data.item.type == 'Spray' ||
                data.item.type == 'Standard-Mischung'
              "
              class="f-p4 text-link text-nowrap"
              @click.prevent="modifySubscription(data.item)"
            >
              <template v-if="data.item.subscription_interval">
                {{ $t("subscriptionButtonEdit") }}
              </template>
              <template v-else>{{ $t("subscriptionButtonConvert") }}</template>
            </b-link>
          </template>

          <template v-slot:custom-foot>
            <b-tr class="sumrow">
              <b-td class="align-top" :rowspan="checkoutData.voucher ? 7 : 6">
                <b-button
                  variant="outline-primary"
                  class="visible-when-wide"
                  @click="onAddIndivSpray()"
                  ><BIconPlusCircle />
                  {{ $t("addSpray", { context: "long" }) }}</b-button
                >
                <b-button
                  variant="outline-primary"
                  class="visible-when-small"
                  @click="onAddIndivSpray()"
                  ><BIconPlusCircle />
                  {{ $t("addSpray", { context: "short" }) }}
                </b-button>
                <CheckoutVoucherInput
                  class="pt-2"
                  :email="checkoutData.billing.email"
                  @set_voucher="setVoucher"
                ></CheckoutVoucherInput>
              </b-td>
              <b-td class="number" colspan="2">{{ $t("cartSum") }}</b-td>
              <b-td class="number" id="productSum">{{
                formatCurrency(cartSummary.productSum)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow" v-if="checkoutData.voucher">
              <b-td class="number" colspan="2">
                {{ $t("voucher") }} {{ checkoutData.voucher.code
                }}<span v-if="checkoutData.voucher.type == 'percent'">
                  {{ checkoutData.voucher.value }}%</span
                >:
              </b-td>
              <b-td class="number" id="voucher">{{
                formatCurrency(cartSummary.voucher)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow">
              <b-td class="number" colspan="2">{{ $t("cartShipping") }}</b-td>
              <b-td class="number" id="shipping">{{
                formatCurrency(shippingCosts)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow">
              <b-td class="number font-weight-bold" colspan="2">{{
                $t("cartTotal")
              }}</b-td>
              <b-td class="number font-weight-bold" id="sum">{{
                formatCurrency(cartSummary.sum)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow">
              <b-td class="number f-p4" colspan="2">{{
                $t("cartTotalWoTax")
              }}</b-td>
              <b-td class="number f-p4" id="netto">{{
                formatCurrency(cartSummary.netto)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow" v-if="cartSummary.tax">
              <b-td class="number f-p4" colspan="2">
                <span
                  v-b-tooltip.hover
                  :title="$t('cartTaxDesc')"
                  :disabled="checkoutData.taxRate"
                >
                  {{
                    $t("cartTax", {
                      taxRate:
                        Math.round(
                          (checkoutData.taxRate ??
                            checkoutData.defaultTaxRate) * 10000
                        ) / 100,
                      descSymbol: checkoutData.taxRate ? "" : "*",
                    })
                  }}
                </span></b-td
              >
              <b-td class="number f-p4" id="tax">{{
                formatCurrency(cartSummary.tax)
              }}</b-td>
            </b-tr>
            <b-tr class="sumrow" v-if="cartSummary.reducedTax">
              <b-td class="number f-p4" colspan="2">
                <span
                  v-b-tooltip.hover
                  :title="$t('cartTaxDesc')"
                  :disabled="checkoutData.reducedTaxRate"
                >
                  {{
                    $t("cartTax", {
                      taxRate:
                        Math.round(
                          (checkoutData.reducedTaxRate ??
                            checkoutData.defaultReducedTaxRate) * 10000
                        ) / 100,
                      descSymbol: checkoutData.reducedTaxRate ? "" : "*",
                    })
                  }}
                </span></b-td
              >
              <b-td class="number f-p4" id="tax">{{
                formatCurrency(cartSummary.reducedTax)
              }}</b-td>
            </b-tr>
          </template>
        </b-table-lite>
        <b-table-simple class="button-table d-sm-none">
          <b-tr>
            <b-td>
              <b-button variant="outline-primary" @click="onAddIndivSpray()"
                ><BIconPlusCircle />
                {{ $t("addSpray", { context: "long" }) }}</b-button
              >
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>
              <CheckoutVoucherInput
                :email="checkoutData.billing.email"
                @set_voucher="setVoucher"
              ></CheckoutVoucherInput>
            </b-td>
          </b-tr>
        </b-table-simple>
        <b-table-simple class="d-sm-none">
          <b-tr class="sumrow">
            <b-td class="number">{{ $t("cartSum") }}</b-td>
            <b-td class="number">{{
              formatCurrency(cartSummary.productSum)
            }}</b-td>
          </b-tr>
          <b-tr class="sumrow" v-if="checkoutData.voucher">
            <b-td class="number">
              Gutschein {{ checkoutData.voucher.code
              }}<span v-if="checkoutData.voucher.type == 'percent'">
                {{ checkoutData.voucher.value }}%</span
              >:
            </b-td>
            <b-td class="number">{{
              formatCurrency(cartSummary.voucher)
            }}</b-td>
          </b-tr>
          <b-tr class="sumrow">
            <b-td class="number">{{ $t("cartShipping") }}</b-td>
            <b-td class="number">{{ formatCurrency(shippingCosts) }}</b-td>
          </b-tr>
          <b-tr class="sumrow">
            <b-td class="number font-weight-bold">{{ $t("cartTotal") }}</b-td>
            <b-td class="number font-weight-bold">{{
              formatCurrency(cartSummary.sum)
            }}</b-td>
          </b-tr>
          <b-tr class="sumrow">
            <b-td class="number f-p4">{{ $t("cartTotalWoTax") }}</b-td>
            <b-td class="number f-p4">{{
              formatCurrency(cartSummary.netto)
            }}</b-td>
          </b-tr>
          <b-tr class="sumrow" v-if="cartSummary.tax">
            <b-td class="number f-p4">
              <span v-b-tooltip.click :title="$t('cartTaxDesc')">
                {{
                  $t("cartTax", {
                    taxRate:
                      Math.round(
                        (checkoutData.taxRate ?? checkoutData.defaultTaxRate) *
                          10000
                      ) / 100,
                    descSymbol: checkoutData.taxRate ? "" : "*",
                  })
                }}
              </span></b-td
            >
            <b-td class="number f-p4">{{
              formatCurrency(cartSummary.tax)
            }}</b-td>
          </b-tr>
          <b-tr class="sumrow" v-if="cartSummary.reducedTax">
            <b-td class="number f-p4">
              <span v-b-tooltip.click :title="$t('cartTaxDesc')">
                {{
                  $t("cartTax", {
                    taxRate:
                      Math.round(
                        (checkoutData.reducedTaxRate ??
                          checkoutData.defaultReducedTaxRate) * 10000
                      ) / 100,
                    descSymbol: checkoutData.reducedtaxRate ? "" : "*",
                  })
                }}
              </span></b-td
            >
            <b-td class="number f-p4">{{
              formatCurrency(cartSummary.reducedTax)
            }}</b-td>
          </b-tr>
        </b-table-simple>
        <div v-if="cross_selling_products.length > 0">
          <div class="font-weight-bold mb-2 ml-3">
            {{ $t("crossSellingTitle") }}
          </div>
          <b-carousel controls class="banner">
            <b-carousel-slide
              v-for="(product, i) in cross_selling_products"
              :key="i"
            >
              <template #img>
                <b-img height="125" :src="product.details.img" />
              </template>
              <template>
                <b-container fluid class="img-placeholder">
                  <b-row>
                    <b-col cols="12" md="7" class="cross-selling-text">
                      <h3 class="text-nowrap">
                        {{ product.details.caption }}
                      </h3>
                      <br />
                      <div v-html="product.details.desc" />
                    </b-col>
                    <b-col cols="12" md="5" class="cross-selling-buttons">
                      <div class="cross-selling-price visible-when-small">
                        {{ $t("crossSellingPricePrefix") }}
                        {{ formatCurrency(product.price) }}
                      </div>
                      <b-button
                        v-if="product.type != 'Spray'"
                        variant="outline-primary"
                        class="secsize"
                        @click="checkoutCSProduct(product)"
                      >
                        {{ $t("crossSellingCartButton") }}
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        class="secsize"
                        @click="
                          openCSProduct(
                            product.type,
                            product.num,
                            product.indication
                          )
                        "
                      >
                        <span v-if="product.type == 'Spray'"
                          >{{ $t("crossSellingSprayButton") }}
                        </span>
                        <span v-else
                          >{{ $t("crossSellingProductButton") }}
                        </span>
                      </b-button>
                      <div class="cross-selling-price visible-when-wide">
                        {{ $t("crossSellingPricePrefix") }}
                        {{ formatCurrency(product.price) }}
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>

        <b-container
          fluid
          xl="*"
          class="express-checkout"
          v-if="express_available"
        >
          <b-row class="pt-4">
            <b-col>
              <h3>{{ $t("expressCheckoutTitle") }}</h3>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="f-p4">
                <i18next :translation="$t('expressDesc')">
                  <template #termsOfUseLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('termsURL')"
                      >{{ $t("terms") }}</b-link
                    >
                  </template>
                  <template #cancellationLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('cancellationURL')"
                      >{{ $t("cancellation") }}</b-link
                    >
                  </template>
                  <template #privacyLink>
                    <b-link
                      target="_blank"
                      tabindex="-1"
                      class="text-link"
                      :href="$t('privacyURL')"
                      >{{ $t("privacy") }}</b-link
                    >
                  </template>
                </i18next>
                <template v-if="cartHasExactlyOneSubscription()">{{
                  $t("onlyForGermany")
                }}</template>
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-4">
            <b-col>
              <div v-if="checkoutState.error" class="error pb-1">
                <span v-html="checkoutState.message"></span>
              </div>
              <div v-if="checkoutState.running">
                <b-spinner small></b-spinner> {{ checkoutState.message }}
              </div>
              <div
                id="paypal-buttons-express"
                :class="checkoutState.running ? 'd-none' : ''"
              ></div>
            </b-col>
          </b-row>
        </b-container>
        <hr />
        <b-form @submit.stop.prevent="doCheckout" id="checkout-form" novalidate>
          <b-container fluid xl="*">
            <b-row>
              <b-col class="address-col" xl>
                <b-container fluid>
                  <b-form-row>
                    <b-col>
                      <div class="billing-adress-header">
                        <span v-if="!checkoutData.shippingDifferent">
                          <h3>{{ $t("addressDeliveryBilling") }}</h3>
                        </span>
                        <span v-if="checkoutData.shippingDifferent">
                          <h3>{{ $t("addressBilling") }}</h3>
                        </span>
                        <b-form-checkbox
                          id="shippingDifferent"
                          @input="userInputTrigger"
                          class="adress-switch"
                          v-model="checkoutData.shippingDifferent"
                          switch
                          >{{ $t("addressDeliverySwitch") }}</b-form-checkbox
                        >
                      </div>
                      <b-form-select
                        @input="userInputTrigger"
                        v-model="checkoutData.billing.salutation"
                        name="billing.salutation"
                        required
                        :options="options_salutation"
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.salutation.required.$invalid
                            ? false
                            : null
                        "
                      ></b-form-select>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.firstname"
                        name="billing.firstname"
                        type="text"
                        required
                        :placeholder="$t('inputFirstname') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.firstname.required.$invalid
                            ? false
                            : null
                        "
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.lastname"
                        name="billing.lastname"
                        type="text"
                        required
                        :placeholder="$t('inputLastname') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.lastname.required.$invalid
                            ? false
                            : null
                        "
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.addition"
                        name="billing.addition"
                        type="text"
                        :placeholder="$t('inputAddition')"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.street"
                        name="billing.street"
                        type="text"
                        required
                        :placeholder="$t('inputStreet') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.street.required.$invalid
                            ? false
                            : null
                        "
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col cols="3">
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.zipcode"
                        name="billing.zipcode"
                        type="text"
                        required
                        :placeholder="$t('inputZipCode') + '*'"
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.zipcode.required.$invalid
                            ? false
                            : null
                        "
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.city"
                        name="billing.city"
                        type="text"
                        required
                        :placeholder="$t('inputCity') + '*'"
                        :state="
                          v$.checkoutData.billing.city.not_numeric.$invalid ||
                          (checkoutState.error &&
                            v$.checkoutData.billing.city.required.$invalid)
                            ? false
                            : null
                        "
                        lazy-formatter
                        :formatter="camelize"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-select
                        @input="userInputTrigger"
                        v-model="checkoutData.billing.country"
                        name="billing.country"
                        required
                        :state="
                          checkoutState.error &&
                          v$.checkoutData.billing.country.required.$invalid
                            ? false
                            : null
                        "
                        :options="options_country"
                      ></b-form-select>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.email"
                        debounce="500"
                        name="billing.email"
                        type="email"
                        required
                        :placeholder="$t('inputEMail') + '*'"
                        :state="
                          v$.checkoutData.billing.email.email.$invalid ||
                          (checkoutState.error &&
                            v$.checkoutData.billing.email.required.$invalid)
                            ? false
                            : null
                        "
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.billing.phone"
                        name="billing.phone"
                        type="tel"
                        :placeholder="$t('inputPhone')"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                </b-container>
              </b-col>
              <b-col
                xl
                class="address-col"
                v-if="checkoutData.shippingDifferent"
              >
                <b-container fluid>
                  <b-form-row>
                    <b-col>
                      <div class="shipping-address-header">
                        <h3>{{ $t("addressDelivery") }}</h3>
                      </div>
                      <b-form-checkbox
                        id="packstation"
                        @input="userInputTrigger"
                        class="adress-switch"
                        v-model="checkoutData.packstation"
                        switch
                        >{{ $t("packstationDelivery") }}</b-form-checkbox
                      >
                      <b-form-select
                        @input="userInputTrigger"
                        v-model="checkoutData.shipping.salutation"
                        name="shipping.salutation"
                        required
                        :options="options_salutation"
                      ></b-form-select>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.firstname"
                        name="shipping.firstname"
                        type="text"
                        required
                        :placeholder="$t('inputFirstname') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.lastname"
                        name="shipping.lastname"
                        type="text"
                        required
                        :placeholder="$t('inputLastname') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.addition"
                        name="shipping.addition"
                        type="text"
                        :placeholder="$t('inputAddition')"
                        v-if="!checkoutData.packstation"
                      ></b-form-input>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.addition"
                        name="shipping.addition"
                        type="number"
                        :placeholder="$t('inputPostNumber') + '*'"
                        required
                        v-if="checkoutData.packstation"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.street"
                        name="shipping.street"
                        type="text"
                        required
                        :placeholder="$t('inputStreet') + '*'"
                        lazy-formatter
                        :formatter="camelize"
                        v-if="!checkoutData.packstation"
                      ></b-form-input>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.packstation"
                        name="shipping.packstation"
                        type="number"
                        required
                        :placeholder="$t('inputPackstation') + '*'"
                        v-if="checkoutData.packstation"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col cols="3">
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.zipcode"
                        name="shipping.zipcode"
                        type="text"
                        required
                        :placeholder="$t('inputZipCode') + '*'"
                      ></b-form-input>
                    </b-col>
                    <b-col>
                      <b-form-input
                        @input="userInputTrigger"
                        v-model.trim="checkoutData.shipping.city"
                        name="shipping.city"
                        type="text"
                        required
                        :placeholder="$t('inputCity') + '*'"
                        :state="
                          v$.checkoutData.shipping.city.$invalid == true
                            ? false
                            : null
                        "
                        lazy-formatter
                        :formatter="camelize"
                      ></b-form-input>
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <b-form-select
                        @input="userInputTrigger"
                        v-model="checkoutData.shipping.country"
                        name="shipping.country"
                        required
                        :options="options_country"
                      ></b-form-select>
                    </b-col>
                  </b-form-row>
                </b-container>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-checkbox
                  @input="userInputTrigger"
                  v-model="checkoutData.termsOfBusiness"
                  name="checkoutData.termsOfBusiness"
                  required
                  value="accepted"
                  unchecked-value="not_accepted"
                  :state="
                    checkoutState.error &&
                    v$.checkoutData.termsOfBusiness.required.$invalid
                      ? false
                      : null
                  "
                >
                  <span id="terms-label">
                    <i18next :translation="$t('inputTerms')">
                      <template #termsOfUseLink>
                        <b-link
                          target="_blank"
                          tabindex="-1"
                          class="text-link"
                          :href="$t('termsURL')"
                          >{{ $t("terms") }}</b-link
                        >
                      </template>
                      <template #cancellationLink>
                        <b-link
                          target="_blank"
                          tabindex="-1"
                          class="text-link"
                          :href="$t('cancellationURL')"
                          >{{ $t("cancellation") }}</b-link
                        >
                      </template>
                      <template #privacyLink>
                        <b-link
                          target="_blank"
                          tabindex="-1"
                          class="text-link"
                          :href="$t('privacyURL')"
                          >{{ $t("privacy") }}</b-link
                        >
                      </template>
                    </i18next>
                  </span>
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row class="pt-4" v-if="cartSummary.sum > 0">
              <b-col>
                <h3>{{ $t("paymentMethod") }}</h3>
                <b-form-radio-group
                  v-model="payment.method"
                  name="payment-method"
                  :required="true"
                  class="payment-options"
                  :class="
                    checkoutState.error && v$.payment.method.required.$invalid
                      ? 'is-invalid'
                      : ''
                  "
                  button-variant="outline-dark"
                  buttons
                  :stacked="$isMobile ? true : false"
                  @change="setPayment"
                  :disabled="checkoutState.running"
                >
                  <b-form-radio
                    value="apple"
                    v-if="wallet_available == 'apple'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 165.52107 105.9651"
                      style="height: 42px"
                    >
                      <use href="@/assets/apple-pay.svg#Artwork"></use>
                    </svg>
                  </b-form-radio>
                  <b-form-radio
                    value="google"
                    v-if="wallet_available == 'google'"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 76 40"
                      style="height: 40px"
                    >
                      <use href="@/assets/google-pay.svg#icon"></use>
                    </svg>
                  </b-form-radio>
                  <b-form-radio
                    value="browser"
                    v-if="wallet_available == 'browser'"
                  >
                    <div>
                      <i18next :translation="$t('paymentBrowser')">
                        <template #lineBreak><br /></template>
                      </i18next>
                    </div>
                  </b-form-radio>
                  <b-form-radio value="card">
                    <div>
                      <b-row class="p-2">
                        <b-col class="px-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 60 40"
                            style="height: 35px"
                            class="pb-2"
                          >
                            <use href="@/assets/visa.svg#icon"></use>
                          </svg>
                        </b-col>
                        <b-col class="px-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 60 40"
                            style="height: 35px"
                            class="pb-2"
                          >
                            <use href="@/assets/mastercard.svg#icon"></use>
                          </svg>
                        </b-col>
                        <b-col class="px-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 60 40"
                            style="height: 35px"
                            class="pb-2"
                          >
                            <use
                              href="@/assets/american-express.svg#icon"
                            ></use>
                          </svg>
                        </b-col>
                      </b-row>
                      {{ $t("paymentCard") }}
                    </div>
                  </b-form-radio>
                  <b-form-radio
                    value="debit"
                    v-if="klarna_available && klarna_available.debit"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-2.40797607 -2.40797607 196.95056214 80.08182114"
                        fill="currentColor"
                        style="height: 1.2rem"
                      >
                        <use href="@/assets/sepa.svg#logo" /></svg
                      ><br />
                      {{ $t("paymentDebit") }}
                    </div>
                  </b-form-radio>
                  <b-form-radio value="paypal">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 60 40"
                        style="height: 35px"
                        class="pb-2"
                      >
                        <use href="@/assets/paypal.svg#icon"></use>
                      </svg>
                      <br />
                      PayPal
                    </div>
                  </b-form-radio>
                  <b-form-radio
                    value="pay_later"
                    v-if="klarna_available && klarna_available.pay_later"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 60 40"
                        style="height: 35px"
                        class="pb-2"
                      >
                        <use href="@/assets/klarna.svg#icon"></use></svg
                      ><br />
                      {{ klarna_available.pay_later.name }}
                    </div>
                  </b-form-radio>
                  <b-form-radio
                    value="pay_over_time"
                    v-if="klarna_available && klarna_available.pay_over_time"
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 60 40"
                        style="height: 35px"
                        class="pb-2"
                      >
                        <use href="@/assets/klarna.svg#icon"></use></svg
                      ><br />
                      {{ klarna_available.pay_over_time.name }}
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
                <div v-if="payment.method == 'card'" key="p-card" class="pt-2">
                  {{ $t("paymentCardInput") }}
                </div>
                <div
                  v-if="payment.method == 'debit' && debit_forbidden"
                  key="p-debit-f"
                  class="error pt-2"
                >
                  {{ $t("paymentNotAvailable") }}
                </div>
                <div
                  v-if="payment.method == 'debit' && !debit_forbidden"
                  key="p-debit"
                  class="pt-2"
                >
                  {{ $t("paymentDebitInput") }}
                </div>
                <div
                  id="stripe-container"
                  class="form-control"
                  v-if="
                    payment.method == 'card' ||
                    (payment.method == 'debit' && !debit_forbidden)
                  "
                  key="p-stripe"
                >
                  <div v-if="payment.error" class="error">
                    {{ $t("paymentLoadError") }}
                  </div>
                  <b-spinner v-if="!payment.error" small></b-spinner>
                </div>
                <b-form-checkbox
                  id="sepaMandate"
                  v-if="payment.method == 'debit' && !debit_forbidden"
                  v-model="checkoutData.sepaMandate"
                  name="checkoutData.sepaMandate"
                  required
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  {{ $t("paymentDebitMandate") }}
                </b-form-checkbox>
                <div
                  id="klarna-container-pay_later"
                  class="pt-2"
                  v-if="payment.method == 'pay_later'"
                  key="p-klarna-pay_later"
                ></div>
                <div
                  id="klarna-container-pay_over_time"
                  class="pt-2"
                  v-if="payment.method == 'pay_over_time'"
                  key="p-klarna-pay_over_time"
                ></div>
              </b-col>
            </b-row>
            <b-row class="pt-4">
              <b-col>
                <div v-if="checkoutState.error" class="error pb-1">
                  <span v-html="checkoutState.message"></span>
                </div>
                <template v-if="!zeroOrder">
                  <div
                    v-if="checkoutState.running && payment.method == 'paypal'"
                  >
                    <b-spinner small></b-spinner> {{ checkoutState.message }}
                  </div>
                  <div
                    v-if="payment.method == 'paypal'"
                    key="p-paypal"
                    id="paypal-buttons"
                    :class="checkoutState.running ? 'd-none' : ''"
                  ></div>
                  <div
                    v-if="
                      payment.method == 'apple' ||
                      payment.method == 'google' ||
                      payment.method == 'browser'
                    "
                    key="p-wallet"
                    id="payment-request-button"
                  ></div>
                </template>
                <b-button
                  v-if="
                    !payment.method ||
                    payment.method == 'card' ||
                    payment.method == 'debit' ||
                    payment.method == 'pay_later' ||
                    payment.method == 'pay_over_time' ||
                    zeroOrder
                  "
                  key="p-button"
                  type="submit"
                  variant="green"
                  :disabled="
                    checkoutState.running ||
                    (payment.method == 'debit' && debit_forbidden)
                  "
                >
                  <b-spinner v-if="checkoutState.running" small></b-spinner>
                  <i18next :translation="$t('orderButtonLabel')">
                    <template #amount>
                      <template v-if="payment.method != 'pay_over_time'">
                        {{ $t("orderButtonAmountPrefix") }}
                        {{ formatCurrency(cartSummary.sum) }}
                      </template>
                    </template>
                  </i18next>
                </b-button>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </div>
    </div>
    <ModalQuantitySelection
      v-if="selected_product && selected_product.product"
      @checkout_spray="quantityUpgradeClick"
      @checkout_subscription="subscriptionUpgradeClick"
      :price="selected_product.product.price"
      :quantityMode="quantityMode"
    ></ModalQuantitySelection>
    <ModalNewSubscription
      v-if="selected_product && selected_product.product"
      @checkout_subscription="updatePositionSubscription"
      :product="selected_product.product"
      :firstname="selected_product.firstname"
      :init_interval="
        selected_product.subscription_interval
          ? selected_product.subscription_interval
          : null
      "
      :init_quantity="selected_product.count ? selected_product.count : null"
      :ok_title="
        selected_product.count
          ? $t('subscriptionUpdateButton')
          : $t('subscriptionAddButton')
      "
    />
    <ModalExitIntent></ModalExitIntent>
  </div>
</template>

<script>
import Vue from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, not, numeric, email } from "@vuelidate/validators";

import { BIconCartX, BIconDash, BIconPlusCircle } from "bootstrap-vue";

import CheckoutVoucherInput from "@/components/CheckoutVoucherInput.vue";
import ModalQuantitySelection from "@/components/ModalQuantitySelection.vue";
import ModalNewSubscription from "@/components/ModalNewSubscription.vue";
import ModalExitIntent from "@/components/ModalExitIntent.vue";

export default {
  name: "CheckoutView",
  components: {
    BIconCartX,
    BIconDash,
    BIconPlusCircle,
    CheckoutVoucherInput,
    ModalQuantitySelection,
    ModalNewSubscription,
    ModalExitIntent,
  },
  i18nOptions: { keyPrefix: "checkout" },
  metaInfo: function () {
    return {
      title: "ZIMPLY NATURAL - " + this.$t("headline"),
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data: function () {
    return {
      checkoutData: {
        billing: {
          salutation: null,
          country: null,
        },
        shippingDifferent: false,
        packstation: false,
        shipping: {
          salutation: null,
          country: null,
        },
        shippingCosts: null,
        missingValueForFreeShipping: null,
        voucher: null,
        taxRate: null,
        reducedTaxRate: null,
        defaultTaxRate: null,
        defaultReducedTaxRate: null,
        pharmacy_id: null,
      },
      checkoutState: {
        error: false,
        message: null,
        running: false,
      },
      userInputTriggered: false,
      exitIntentAlreadyShown: false,
      payment: {
        provider: null,
        method: null,
        id: {},
        token: {},
        data_holder: null,
        error: false,
      },
      wallet_available: null,
      klarna_available: null,
      debit_forbidden: false,
      express_available: false,
      stripe: {
        api: null,
        style: {
          base: {
            color: "#495057",
            fontFamily:
              'Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#6c757d",
            },
          },
          invalid: {
            color: "#dc3545",
            iconColor: "#dc3545",
          },
        },
        element_options: {
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css?family=Nunito%3A400&#038;subset=latin&#038;ver=5.5.3",
            },
          ],
          locale: this.$i18next.resolvedLanguage,
        },
      },
      selected_product: null,
      product_unknown: false,
      reload_paypal: false,
      cross_selling_products: [],
      quantityMode: null,
    };
  },
  validations: {
    checkoutData: {
      billing: {
        salutation: {
          required,
        },
        firstname: {
          required,
        },
        lastname: {
          required,
        },
        street: {
          required,
        },
        zipcode: {
          required,
        },
        city: {
          required,
          not_numeric: not(numeric),
        },
        country: {
          required,
        },
        email: {
          required,
          email,
        },
      },
      shipping: {
        city: {
          not_numeric: not(numeric),
        },
      },
      termsOfBusiness: {
        required: (value) => value === "accepted",
      },
    },
    payment: {
      method: {
        required,
      },
    },
  },
  mounted: async function () {
    if (this.$route.params.quantityMode) {
      this.quantityMode = this.$route.params.quantityMode;
      if (this.quantityMode == "quantity-upgrade") {
        this.showQuantityUpgrade(this.$cart[this.$cart.length - 1]);
      }
    } else if (
      this.$route.params.product_num &&
      this.$route.params.product_num.startsWith("B:")
    ) {
      this.quantityMode = "cart-only";
    } else {
      this.$EventBus.$emit("execute_abtest", "Quantity_Selection", [
        {
          name: "original",
          activate: () => {
            this.quantityMode = "quantity-selection";
          },
        },
        {
          name: "Upgrade",
          activate: () => {
            this.quantityMode = "quantity-upgrade";
          },
        },
        {
          name: "CartOnly",
          activate: () => {
            this.quantityMode = "cart-only";
          },
        },
      ]);
    }

    setTimeout(() => {
      if (this.quantityMode == null) {
        this.quantityMode = "quantity-selection";
      }
    }, 750);

    this.checkoutState.error = false;
    this.userInputTriggered = false;
    await this.$sessionAvailable;
    await this.$userChecked;
    this.paymentPrecheck();
    this.checkoutData.pharmacy_id = this.$pharmacy.id;
    this.retrieveUserDefaultAddress();
    this.loadCrossSellingProducts();

    setTimeout(() => {
      document.documentElement.addEventListener(
        "mouseleave",
        this.exitIntentEventListener
      );
    }, 5000);
  },
  destroyed() {
    document.documentElement.removeEventListener(
      "mouseleave",
      this.exitIntentEventListener
    );
  },
  computed: {
    cartFields: function () {
      return [
        { key: "name", label: this.$t("cartTableHeader", { context: "name" }) },
        {
          key: "count",
          class: "count",
          label: this.$t("cartTableHeader", { context: "count" }),
        },
        {
          key: "price",
          formatter: (value) => this.formatCurrency(value),
          class: "number",
          label: this.$t("cartTableHeader", { context: "price" }),
        },
        {
          key: "sum",
          formatter: (value) => this.formatCurrency(value),
          class: "number",
          label: this.$t("cartTableHeader", { context: "sum" }),
        },
      ];
    },
    options_salutation: function () {
      return [
        {
          value: null,
          text: this.$t("salutation", { context: "Label" }),
          disabled: true,
        },
        { value: "Herr", text: this.$t("salutation", { context: "Mr" }) },
        { value: "Frau", text: this.$t("salutation", { context: "Ms" }) },
      ];
    },
    options_country: function () {
      let countries = [
        {
          value: null,
          text: this.$t("country", { context: "Label" }),
          disabled: true,
          rank: 0,
        },
        {
          value: { name: "Deutschland", code: "DE" },
          text: this.$t("country", { context: "DE" }),
          rank: 1,
        },
        {
          value: { name: "Österreich", code: "AT" },
          text: this.$t("country", { context: "AT" }),
          rank: 2,
        },
        {
          value: { name: "Belgien", code: "BE" },
          text: this.$t("country", { context: "BE" }),
          rank: 5,
        },
        {
          value: { name: "Bulgarien", code: "BG" },
          text: this.$t("country", { context: "BG" }),
          rank: 5,
        },
        {
          value: { name: "Dänemark", code: "DK" },
          text: this.$t("country", { context: "DK" }),
          rank: 5,
        },
        {
          value: { name: "Estland", code: "EE" },
          text: this.$t("country", { context: "EE" }),
          rank: 5,
        },
        {
          value: { name: "Finnland", code: "FI" },
          text: this.$t("country", { context: "FI" }),
          rank: 5,
        },
        {
          value: { name: "Frankreich", code: "FR" },
          text: this.$t("country", { context: "FR" }),
          rank: 5,
        },
        {
          value: { name: "Griechenland", code: "GR" },
          text: this.$t("country", { context: "GR" }),
          rank: 5,
        },
        {
          value: { name: "Irland", code: "IE" },
          text: this.$t("country", { context: "IE" }),
          rank: 5,
        },
        {
          value: { name: "Italien", code: "IT" },
          text: this.$t("country", { context: "IT" }),
          rank: 5,
        },
        {
          value: { name: "Kroatien", code: "HR" },
          text: this.$t("country", { context: "HR" }),
          rank: 5,
        },
        {
          value: { name: "Lettland", code: "LV" },
          text: this.$t("country", { context: "LV" }),
          rank: 5,
        },
        {
          value: { name: "Litauen", code: "LT" },
          text: this.$t("country", { context: "LT" }),
          rank: 5,
        },
        {
          value: { name: "Luxemburg", code: "LU" },
          text: this.$t("country", { context: "LU" }),
          rank: 5,
        },
        {
          value: { name: "Malta", code: "MT" },
          text: this.$t("country", { context: "MT" }),
          rank: 5,
        },
        {
          value: { name: "Niederlande", code: "NL" },
          text: this.$t("country", { context: "NL" }),
          rank: 5,
        },
        {
          value: { name: "Polen", code: "PL" },
          text: this.$t("country", { context: "PL" }),
          rank: 5,
        },
        {
          value: { name: "Portugal", code: "PT" },
          text: this.$t("country", { context: "PT" }),
          rank: 5,
        },
        {
          value: { name: "Rumänien", code: "RO" },
          text: this.$t("country", { context: "RO" }),
          rank: 5,
        },
        {
          value: { name: "Schweden", code: "SE" },
          text: this.$t("country", { context: "SE" }),
          rank: 5,
        },
        {
          value: { name: "Slowakei", code: "SK" },
          text: this.$t("country", { context: "SK" }),
          rank: 5,
        },
        {
          value: { name: "Slowenien", code: "SI" },
          text: this.$t("country", { context: "SI" }),
          rank: 5,
        },
        {
          value: { name: "Spanien", code: "ES" },
          text: this.$t("country", { context: "ES" }),
          rank: 5,
        },
        {
          value: { name: "Tschechien", code: "CZ" },
          text: this.$t("country", { context: "CZ" }),
          rank: 5,
        },
        {
          value: { name: "Ungarn", code: "HU" },
          text: this.$t("country", { context: "HU" }),
          rank: 5,
        },
        {
          value: { name: "Zypern", code: "CY" },
          text: this.$t("country", { context: "CY" }),
          rank: 5,
        },
      ];
      countries.sort((a, b) => {
        if (a.rank - b.rank != 0) {
          return a.rank - b.rank;
        }
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      return countries;
    },
    cartSummary: function () {
      let rtn = {
        productSum: 0,
        voucher: 0,
        netto: 0,
        tax: 0,
        reducedTax: 0,
      };
      let cntItems = 0;
      let taxRate =
        this.checkoutData.taxRate ?? this.checkoutData.defaultTaxRate;
      let reducedTaxRate =
        this.checkoutData.reducedTaxRate ??
        this.checkoutData.defaultReducedTaxRate;
      let reducedBrutto = 0;
      this.$cart.forEach((position) => {
        rtn.productSum += position.sum;
        cntItems += position.count;
        let positionNetto = position.sum;
        if (position.product.reduced_tax) {
          positionNetto = position.sum / (1 + reducedTaxRate);
          rtn.reducedTax += position.sum - positionNetto;
          reducedBrutto += position.sum;
        } else {
          positionNetto = position.sum / (1 + taxRate);
          rtn.tax += position.sum - positionNetto;
        }
        rtn.netto += positionNetto;
      });
      rtn.sum = rtn.productSum;
      let taxRatio = 1 - reducedBrutto / rtn.sum;
      if (this.checkoutData.voucher) {
        if (this.checkoutData.voucher.type == "percent") {
          rtn.voucher = (rtn.sum * this.checkoutData.voucher.value) / 100;
        } else {
          rtn.voucher = this.checkoutData.voucher.value;
        }
        rtn.voucher = Math.min(rtn.sum, rtn.voucher) * -1;
      }
      let voucherTax = rtn.voucher * taxRatio * (taxRate / (1 + taxRate));
      let voucherReducedTax =
        rtn.voucher * (1 - taxRatio) * (reducedTaxRate / (1 + reducedTaxRate));
      rtn.shippingCosts =
        typeof this.shippingCosts === "string" ? 0 : this.shippingCosts;
      rtn.sum = rtn.sum + rtn.voucher + rtn.shippingCosts;
      let shippingCostTax =
        rtn.shippingCosts * taxRatio * (taxRate / (1 + taxRate));
      let shippingCostReducedTax =
        rtn.shippingCosts *
        (1 - taxRatio) *
        (reducedTaxRate / (1 + reducedTaxRate));
      rtn.netto =
        rtn.netto +
        (rtn.shippingCosts - shippingCostTax - shippingCostReducedTax) +
        (rtn.voucher - voucherTax - voucherReducedTax);
      rtn.tax = rtn.tax + voucherTax + shippingCostTax;
      rtn.reducedTax =
        rtn.reducedTax + voucherReducedTax + shippingCostReducedTax;
      rtn.productSum = this.round(rtn.productSum);
      rtn.voucher = this.round(rtn.voucher);
      rtn.sum = this.round(rtn.sum);
      rtn.netto = this.round(rtn.netto);
      rtn.tax =
        this.round(rtn.tax + rtn.reducedTax) - this.round(rtn.reducedTax);
      rtn.reducedTax = this.round(rtn.reducedTax);
      rtn.cntItems = cntItems;
      return rtn;
    },
    zeroOrder: function () {
      return this.cartSummary.sum == 0 && this.cartSummary.voucher < 0;
    },
    billingCountryCode: function () {
      let country_code = "DE";
      if (
        this.checkoutData.billing.country != null &&
        this.checkoutData.billing.country.code != null
      ) {
        country_code = this.checkoutData.billing.country.code;
      }
      return country_code;
    },
    shippingCountryCode: function () {
      let country_code = null;
      if (
        this.checkoutData.shippingDifferent &&
        this.checkoutData.shipping.country != null &&
        this.checkoutData.shipping.country.code != null
      ) {
        country_code = this.checkoutData.shipping.country.code;
      } else if (
        !this.checkoutData.shippingDifferent &&
        this.checkoutData.billing.country != null &&
        this.checkoutData.billing.country.code != null
      ) {
        country_code = this.checkoutData.billing.country.code;
      }
      return country_code;
    },
    shippingCosts: function () {
      let shippingCosts = this.$t("shippingCostsNotSet");
      if (this.checkoutData.shippingCosts != null) {
        shippingCosts = this.checkoutData.shippingCosts;
      }
      return shippingCosts;
    },
  },
  watch: {
    $user() {
      this.retrieveUserDefaultAddress();
    },
    quantityMode() {
      if (this.$route.params.product_num) {
        if (this.quantityMode == "cart-only") {
          let isBundle = this.$route.params.product_num.startsWith("B:");
          this.loadProductWithCallback(
            this.$route.params.product_num,
            (product) => {
              if (isBundle) {
                this.checkoutBundle(product);
              } else {
                this.checkoutProduct(product, 1);
              }
            }
          );
        } else if (this.quantityMode == "quantity-upgrade") {
          this.loadProductWithCallback(
            this.$route.params.product_num,
            (product) => {
              this.checkoutProduct(product, 1);
              this.showQuantityUpgrade(this.$cart[this.$cart.length - 1]);
            }
          );
        } else {
          this.loadProductWithCallback(
            this.$route.params.product_num,
            (product) => {
              this.showQuantitySelection(product);
            }
          );
        }
      }
    },
    shippingCountryCode(newCountryCode) {
      if (newCountryCode != null) {
        this.getShippingCosts(newCountryCode);
      } else {
        this.checkoutData.shippingCosts = null;
      }
    },
    cartSummary(newCartSummary, oldCartSummary) {
      if (
        this.shippingCountryCode != null &&
        oldCartSummary.sum != newCartSummary.sum &&
        oldCartSummary.shippingCosts == newCartSummary.shippingCosts
      ) {
        this.getShippingCosts(this.shippingCountryCode);
      }
    },
  },
  methods: {
    updateCart: function (index, count) {
      let oldCount = this.$cart[index].count;
      this.$cart[index].count = count;

      if (oldCount === 0 && count === 0) {
        this.$cart.splice(index, 1);
      }
    },
    buildCheckoutData: function () {
      this.checkoutData.cart = this.$cart;
      this.checkoutData.cartSummary = this.cartSummary;
      this.checkoutData.paymentInfo = {
        provider: this.payment.provider,
        method: this.payment.method,
        id: this.payment.id[this.payment.provider],
      };
      if (!this.checkoutData.uuid) {
        this.checkoutData.uuid = this.generateUuid();
      }
      if (this.checkoutData.packstation) {
        this.checkoutData.shipping.street =
          "Packstation " + this.checkoutData.shipping.packstation;
      }
      this.checkoutData.partner = this.$order_info.partner;
      this.checkoutData.lang = this.$i18next.resolvedLanguage;
      return this.checkoutData;
    },
    resetCheckoutState: function () {
      this.checkoutState.running = false;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateRunning: function () {
      this.checkoutState.running = true;
      this.checkoutState.error = false;
      this.checkoutState.message = null;
    },
    setCheckoutStateError: function (message) {
      this.checkoutState.running = false;
      this.checkoutState.error = true;
      this.checkoutState.message = message;
    },
    resetPaymentMethod: function () {
      this.payment.method = null;
      this.payment.provider = null;
    },
    paymentPrecheck: function () {
      if (this.cartSummary.sum <= 0) {
        this.klarna_available = {};
        this.express_available = false;
        return;
      }

      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/payment-precheck", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cart: this.$cart,
          cartSummary: this.cartSummary,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.success) {
            this.loadPayPal().then((res) => {
              if (res && !this.cartHasMoreThanOneSubscription()) {
                this.express_available = true;
                Vue.nextTick(() => {
                  this.enablePayPalCheckout("express");
                });
              } else {
                this.express_available = false;
              }
            });
            this.checkWalletAvailability(result.stripe_pk);
            this.checkKlarnaAvailability(result.klarna_token, result.methods);
            this.checkoutData.defaultTaxRate = parseFloat(
              result.default_vat_rates.rate
            );
            this.checkoutData.defaultReducedTaxRate = parseFloat(
              result.default_vat_rates.reduced_rate
            );
          }
        });
    },
    loadPayPal: function () {
      if (this.cartHasExactlyOneSubscription()) {
        return this._loadPayPalIfFromGermany(true);
      }
      if (this.cartHasMoreThanOneSubscription()) {
        this.reload_paypal = true;
        return new Promise((resolve) => {
          resolve(false);
        });
      }
      return this._loadPayPal(false);
    },
    checkWalletAvailability: function (pk) {
      /*global Stripe */
      this.stripe.api = Stripe(pk);
      this.buildPaymentRequest()
        .canMakePayment()
        .then((result) => {
          if (result) {
            if (result.applePay) {
              this.wallet_available = "apple";
            } else if (result.googlePay) {
              this.wallet_available = "google";
            } else {
              this.wallet_available = "browser";
            }
          }
        });
    },
    checkKlarnaAvailability: function (token, methods) {
      this.klarna_available = {};
      methods.forEach((method) => {
        this.klarna_available[method.id] = {
          name: method.name,
          logo: method.logo,
        };
      });
      /*global Klarna */
      Klarna.Payments.init({
        client_token: token,
      });
    },
    buildPaymentRequest: function () {
      return this.stripe.api.paymentRequest({
        country: this.billingCountryCode,
        currency: "eur",
        total: {
          label: "ZIMPLY NATURAL",
          amount: Math.round(this.cartSummary.sum * 100),
        },
        requestPayerName: false,
        requestPayerPhone: false,
        requestPayerEmail: false,
        requestShipping: false,
      });
    },
    buildKlarnaPaymentDetails: function (withAddress) {
      let result = {
        purchase_country: this.billingCountryCode,
        order_amount: Math.round(this.cartSummary.sum * 100),
        order_lines: [],
      };

      let discount = this.cartSummary.voucher * -1;
      let posCountSum = 0;
      this.$cart.forEach((position) => {
        posCountSum += position.count;
      });
      this.$cart
        .filter((p) => p.count > 0)
        .forEach((position) => {
          let posDiscount = (discount / posCountSum) * position.count;
          result.order_lines.push({
            name: position.name,
            quantity: position.count,
            total_amount: Math.round((position.sum - posDiscount) * 100),
            total_discount_amount: Math.round(posDiscount * 100),
            unit_price: Math.round(position.price * 100),
          });
        });

      if (this.cartSummary.shippingCosts) {
        result.order_lines.push({
          type: "shipping_fee",
          name: "Shipping",
          quantity: 1,
          unit_price: Math.round(this.cartSummary.shippingCosts * 100),
          total_amount: Math.round(this.cartSummary.shippingCosts * 100),
        });
      }

      if (withAddress) {
        result.billing_address = {
          given_name: this.checkoutData.billing.firstname,
          family_name: this.checkoutData.billing.lastname,
          email: this.checkoutData.billing.email,
          title: this.checkoutData.billing.salutation,
          street_address: this.checkoutData.billing.street,
          postal_code: this.checkoutData.billing.zipcode,
          city: this.checkoutData.billing.city,
          country: this.checkoutData.billing.country.code,
        };
        if (this.checkoutData.shippingDifferent) {
          result.shipping_address = {
            given_name: this.checkoutData.shipping.firstname,
            family_name: this.checkoutData.shipping.lastname,
            email: this.checkoutData.billing.email,
            title: this.checkoutData.shipping.salutation,
            street_address: this.checkoutData.shipping.street,
            postal_code: this.checkoutData.shipping.zipcode,
            city: this.checkoutData.shipping.city,
            country: this.checkoutData.shipping.country.code,
          };
        }
      }

      return result;
    },
    doCheckout: async function () {
      let formValid = document.getElementById("checkout-form").reportValidity();
      this.v$.checkoutData.$touch();
      if (!formValid || this.v$.checkoutData.$errors.length > 0) {
        this.setCheckoutStateError(this.$t("errorMissingFields"));
        Vue.nextTick(() => {
          let invalid = document.getElementsByClassName("is-invalid");
          if (invalid.length > 0) {
            invalid[0].scrollIntoView({
              behavior: "smooth",
            });
          }
        });
        return;
      }

      this.setCheckoutStateRunning();

      if (this.hasCartChanged() || this.payment.method == "debit") {
        if (this.zeroOrder) {
          this.resetPaymentMethod();
        } else if (this.payment.provider != "Klarna") {
          let res = await this.preparePayment();
          if (!res) {
            this.setCheckoutStateError(this.$t("errorUpdatePayment"));
            return;
          }
        }
      }

      let res = await this.checkShipping();
      if (res && !res.success) {
        let msg =
          '<span id="shipping-check-error">' +
          this.$t("errorAddressInvalidCaption") +
          "</span><br/>";
        if (res.errors) {
          msg += "<ul>";
          res.errors.forEach((error) => {
            msg +=
              "<li>" +
              this.$t("errorAddressInvalid", { context: error }) +
              "</li>";
          });
          msg += "</ul>";
          msg += this.$t("errorAddressInvalidAction1");
        } else {
          msg += this.$t("errorAddressInvalidAction2");
        }
        this.setCheckoutStateError(msg);
        return;
      }

      if (!(await this.checkVoucher())) return;

      if (this.payment.method == "card") {
        this.stripe.api
          .confirmCardPayment(this.payment.token[this.payment.provider], {
            payment_method: {
              card: this.payment.data_holder,
              billing_details: {
                name:
                  this.checkoutData.billing.firstname +
                  " " +
                  this.checkoutData.billing.lastname,
                address: {
                  postal_code: this.checkoutData.billing.zipcode,
                },
              },
            },
          })
          .then((result) => {
            if (result.error) {
              this.setCheckoutStateError(this.$t("errorPaymentInvalid"));
            } else {
              this.finishCheckout();
            }
          });
      } else if (this.payment.method == "debit" && !this.debit_forbidden) {
        this.checkoutData.paymentInfo.sepaMandate =
          this.checkoutData.sepaMandate;
        if (this.checkoutData.sepaMandate != "accepted") {
          this.setCheckoutStateError(this.$t("errorSepaMandateMissing"));
          return;
        }
        this.stripe.api
          .confirmSepaDebitPayment(this.payment.token[this.payment.provider], {
            payment_method: {
              sepa_debit: this.payment.data_holder,
              billing_details: {
                name:
                  this.checkoutData.billing.firstname +
                  " " +
                  this.checkoutData.billing.lastname,
                email: this.checkoutData.billing.email,
              },
            },
          })
          .then((result) => {
            if (result.error) {
              this.setCheckoutStateError(this.$t("errorPaymentInvalid"));
            } else {
              this.finishCheckout();
            }
          });
      } else if (this.payment.provider == "PayPal") {
        if (this.cartHasExactlyOneSubscription()) {
          await this.preparePayPalSubscription();
        }
      } else if (this.payment.provider == "Klarna") {
        Klarna.Payments.authorize(
          {
            payment_method_category: this.payment.method,
          },
          this.buildKlarnaPaymentDetails(true),
          (result) => {
            if (result.approved) {
              this.checkoutData.paymentInfo.authorization_token =
                result.authorization_token;
              this.finishCheckout();
            } else {
              if (!result.show_form) {
                this.setCheckoutStateError(
                  this.$t("errorPaymentMethodNotAvailable")
                );
                this.resetPaymentMethod();
              } else {
                this.setCheckoutStateError(this.$t("errorPaymentNotConfirmed"));
              }
            }
          }
        );
      } else if (this.zeroOrder) {
        this.finishCheckout();
      }
    },
    hasCartChanged: function () {
      let tmp = 0;
      if (this.checkoutData.cartSummary != null)
        tmp = this.checkoutData.cartSummary.sum;
      this.buildCheckoutData();
      if (this.checkoutData.cartSummary.sum != tmp) {
        return true;
      } else {
        return false;
      }
    },
    finishCheckout: function () {
      this._finishCheckout(this.checkoutData).then((result) => {
        if (result) {
          this.$cart.splice(0, this.$cart.length);
          this.resetCheckoutState();
          this.$router.push("/thankyou");
        } else {
          this.setCheckoutStateError(this.$t("errorStore"));
        }
      });
    },
    preparePayment: function () {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/prepare-payment", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.checkoutData),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              this.payment.id[this.payment.provider] = result.id;
              this.payment.token[this.payment.provider] = result.token;
              if (this.payment.provider == "Stripe") {
                if (this.stripe.api == null)
                  this.stripe.api = Stripe(result.pk);
                if (!result.methods.includes("debit")) {
                  this.debit_forbidden = true;
                }
              }
              if (result.orderId) {
                this.checkoutData.orderId = result.orderId;
              }
              resolve(result.id);
            } else {
              resolve(false);
            }
          });
      });
    },
    setPayment: async function () {
      this.resetCheckoutState();
      this.payment.error = false;

      this.payment.provider =
        this.payment.method == "paypal"
          ? "PayPal"
          : this.payment.method == "pay_later"
          ? "Klarna"
          : this.payment.method == "pay_over_time"
          ? "Klarna"
          : "Stripe";

      if (
        this.payment.provider == "Stripe" &&
        !this.payment.token[this.payment.provider]
      ) {
        this.buildCheckoutData();
        let res = await this.preparePayment();
        if (!res) {
          this.setCheckoutStateError(this.$t("errorPaymentPrepare"));
          this.payment.error = true;
          this.resetPaymentMethod();
          return;
        }
      }

      if (this.payment.method == "card") {
        let elements = this.stripe.api.elements(this.stripe.element_options);
        this.payment.data_holder = elements.create("card", {
          style: this.stripe.style,
          hidePostalCode: true,
        });
        this.payment.data_holder.mount("#stripe-container");
      } else if (this.payment.method == "debit" && !this.debit_forbidden) {
        let elements = this.stripe.api.elements(this.stripe.element_options);
        this.payment.data_holder = elements.create("iban", {
          style: this.stripe.style,
          supportedCountries: ["SEPA"],
          placeholderCountry: this.billingCountryCode,
        });
        this.payment.data_holder.mount("#stripe-container");
      } else if (
        this.payment.method == "apple" ||
        this.payment.method == "google" ||
        this.payment.method == "browser"
      ) {
        await this.doCheckout();
        if (this.checkoutState.error) {
          this.resetPaymentMethod();
          return;
        }
        this.resetCheckoutState();

        this.enableWalletCheckout().then((payment_event) => {
          if (payment_event) {
            // check if cart has changed and fail if so
            if (this.hasCartChanged()) {
              this.setCheckoutStateError(this.$t("errorPaymentCartChanged"));
              this.resetPaymentMethod();
              this.preparePayment();
              payment_event.complete("fail");
              return;
            }

            // re-check if something other is invalid and fail if so
            this.doCheckout().then(() => {
              if (this.checkoutState.error) {
                this.resetPaymentMethod();
                payment_event.complete("fail");
                return;
              }

              this.confirmWalletCheckout(payment_event);
            });
          } else {
            this.setCheckoutStateError(this.$t("errorPaymentInitiation"));
          }
        });
      } else if (this.payment.method == "paypal") {
        if (this.cartHasMoreThanOneSubscription()) {
          this.setCheckoutStateError(
            this.$t("errorPaymentSubscriptionPayPalOnlyOne")
          );
          this.resetPaymentMethod();
        } else {
          this.enablePayPalCheckout("checkout");
        }
      } else if (this.payment.provider == "Klarna") {
        Klarna.Payments.load(
          {
            container: "#klarna-container-" + this.payment.method,
            payment_method_category: this.payment.method,
          },
          this.buildKlarnaPaymentDetails(false),
          (result) => {
            if (!result.show_form) {
              this.setCheckoutStateError(
                this.$t("errorPaymentMethodNotAvailable")
              );
              this.resetPaymentMethod();
            }
          }
        );
      }
    },
    enablePayPalCheckout: async function (mode) {
      let createSubscription = null;
      let createOrder = null;
      let onApprove = null;
      if (this.cartHasExactlyOneSubscription()) {
        if (mode == "express") {
          createSubscription = (data, actions) => {
            return actions.subscription.create({
              plan_id: this.$cart[0].subscription_plan_id,
              quantity: this.$cart[0].count,
            });
          };
        } else {
          createSubscription = () => {
            return this.preparePayment();
          };
        }
        onApprove = (data) => {
          this.checkoutState.message = this.$t("messageProcessing");
          this.checkoutData.paymentInfo = {
            ...this.checkoutData.paymentInfo,
            id: data["orderID"],
            ...data,
          };
          this.finishCheckout();
        };
      } else {
        createOrder = () => {
          return this.preparePayment();
        };
        onApprove = (data, actions) => {
          return actions.order.capture().then((details) => {
            this.checkoutState.message = this.$t("messageProcessing");
            try {
              this.checkoutData.paymentInfo.orderID = details.id;
              this.checkoutData.paymentInfo.id =
                details.purchase_units[0].payments.captures[0].id;
            } catch (e) {
              // ignore
            }
            this.finishCheckout();
          });
        };
      }
      if (this.reload_paypal) {
        await this.loadPayPal();
      }
      if (
        document.getElementById(
          "paypal-buttons" + (mode == "express" ? "-express" : "")
        )
      ) {
        /*global paypal */
        paypal
          .Buttons({
            style: {
              layout: "vertical",
              color: mode == "express" ? "gold" : "white",
              shape: "rect",
              height: 38,
              label: "buynow",
            },
            onClick: async (data, actions) => {
              this.payment.method =
                "paypal" + (mode == "express" ? "-express-c" : "");
              this.payment.provider = "PayPal";
              this.buildCheckoutData();
              if (mode == "express") {
                this.setCheckoutStateRunning();
                if (!(await this.checkVoucher())) {
                  return actions.reject();
                }
                if (this.cartHasExactlyOneSubscription()) {
                  await this.preparePayPalSubscription();
                }
              } else {
                await this.doCheckout();
              }
              if (this.checkoutState.error) {
                return actions.reject();
              }
              this.checkoutState.message = this.$t("messageConfirmPayPal");
              return actions.resolve();
            },
            createOrder: createOrder,
            createSubscription: createSubscription,
            onError: () => {
              this.setCheckoutStateError(this.$t("errorPaymentExecution"));
            },
            onCancel: () => {
              this.resetCheckoutState();
            },
            onApprove: onApprove,
            onShippingChange: async (data, actions) => {
              if (
                !this.validCountry().includes(
                  data.shipping_address.country_code
                )
              ) {
                this.logMessageOnServer(
                  "ERROR: Reject PayPal payment for country " +
                    data.shipping_address.country_code
                );
                return actions.reject();
              }
              this.checkoutData.paymentInfo.orderID = data.orderID;
              let country = this.optionsCountry().filter(
                (country) =>
                  country.value &&
                  country.value.code == data.shipping_address.country_code
              )[0].value;
              if (this.checkoutData.shippingDifferent) {
                this.checkoutData.shipping.country = country;
              } else {
                this.checkoutData.billing.country = country;
              }
              let result = await fetch(
                this.$ZNC_BACKEND_BASE + "/business/v1/update-payment",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(this.checkoutData),
                }
              );
              if (result.ok) {
                return actions.resolve();
              } else {
                this.logMessageOnServer(
                  "ERROR: Reject PayPal payment after error"
                );
                return actions.reject();
              }
            },
          })
          .render("#paypal-buttons" + (mode == "express" ? "-express" : ""));
      }
    },
    preparePayPalSubscription: function () {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/prepare-subscription", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            product: this.$cart[0].product,
            interval: this.$cart[0].subscription_interval,
            quantity: this.$cart[0].count,
            customer: this.checkoutData.billing.email,
            mixture_num: this.$cart[0].mixture,
            mixture_code: this.$cart[0].code,
            mixture_firstname: this.$cart[0].firstname,
            pharmacy_id: this.$pharmacy.id,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              this.$cart[0].subscription_plan_id = result.plan_id;
            } else {
              this.setCheckoutStateError(this.$t("errorPaymentInitiation"));
            }
            resolve();
          });
      });
    },
    enableWalletCheckout: function () {
      return new Promise((resolve) => {
        let paymentRequest = this.buildPaymentRequest();
        paymentRequest.canMakePayment().then((result) => {
          if (result) {
            let elements = this.stripe.api.elements(
              this.stripe.element_options
            );
            let button = elements.create("paymentRequestButton", {
              paymentRequest: paymentRequest,
              style: this.stripe.style,
            });

            button.mount("#payment-request-button");
            paymentRequest.on("paymentmethod", (e) => {
              resolve(e);
            });
          } else {
            resolve(null);
          }
        });
      });
    },
    confirmWalletCheckout: function (payment_event) {
      this.stripe.api
        .confirmCardPayment(
          this.payment.token[this.payment.provider],
          { payment_method: payment_event.paymentMethod.id },
          { handleActions: false }
        )
        .then((confirmResult) => {
          if (confirmResult.error) {
            payment_event.complete("fail");
          } else {
            payment_event.complete("success");
            if (confirmResult.paymentIntent.status === "requires_action") {
              this.stripe.api
                .confirmCardPayment(this.payment.token[this.payment.provider])
                .then((result) => {
                  if (result.error) {
                    this.setCheckoutStateError(
                      this.$t("errorPaymentExecution")
                    );
                  } else {
                    this.finishCheckout();
                  }
                });
            } else {
              this.finishCheckout();
            }
          }
        });
    },
    getShippingCosts: function (country_code) {
      return new Promise((resolve) => {
        let cartSummary = JSON.parse(JSON.stringify(this.cartSummary));
        cartSummary.sum = this.round(
          cartSummary.sum - cartSummary.shippingCosts
        );
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/shipping/costs", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            cart: this.$cart,
            cartSummary: cartSummary,
            country: country_code,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            let oldShippingCosts = this.checkoutData.shippingCosts;
            this.checkoutData.shippingCosts = parseFloat(result.shipping_costs);
            this.checkoutData.missingValueForFreeShipping =
              result.missing_value_for_free_shipping
                ? parseFloat(result.missing_value_for_free_shipping)
                : null;
            if (
              this.checkoutData.shippingCosts != null &&
              this.checkoutData.shippingCosts != oldShippingCosts
            ) {
              this.$bvToast.toast(
                this.$t("shippingCostsChange", {
                  shippingCosts: this.formatCurrency(
                    this.checkoutData.shippingCosts
                  ),
                }),
                {
                  noCloseButton: true,
                  toaster: "b-toaster-bottom-center",
                }
              );
            }
            this.checkoutData.taxRate = result.vat_rates.rate
              ? parseFloat(result.vat_rates.rate)
              : null;
            this.checkoutData.reducedTaxRate = result.vat_rates.reduced_rate
              ? parseFloat(result.vat_rates.reduced_rate)
              : null;
            resolve(result);
          });
      });
    },
    checkShipping: function () {
      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/shipping/check", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.checkoutData),
        })
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            resolve(result);
          });
      });
    },
    setVoucher: function (voucher) {
      this.checkoutData.voucher = voucher;
      this.resetPaymentMethod();
    },
    checkVoucher: function () {
      if (!this.checkoutData.voucher) return true;

      return new Promise((resolve) => {
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/voucher", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: this.checkoutData.voucher.code,
            email: this.checkoutData.billing.email,
            cart: this.$cart,
          }),
        }).then((response) => {
          if (response.ok) {
            resolve(true);
          } else {
            let msg = "";
            if (response.status == 406) {
              msg += this.$t("errorVoucherAlreadyUsed");
            } else {
              msg += this.$t("errorVoucherInvalidRemoved");
            }
            this.$EventBus.$emit("clear_voucher_input");
            this.checkoutData.voucher = null;
            this.setCheckoutStateError(msg);
            resolve(false);
          }
        });
      });
    },
    userInputTrigger: function () {
      if (!this.userInputTriggered) {
        this.$EventBus.$emit("gtag", {
          event: "konfigurator-checkout",
          schritt: "formulareingabe",
        });
        this.$EventBus.$emit("gtag_ecom", {
          event: "checkout",
          ecommerce: {
            currencyCode: "EUR",
            checkout: {
              actionField: { step: 1 },
              products: this.$cart,
            },
          },
          cart: this.$cart,
        });
        this.userInputTriggered = true;
      }
    },
    camelize: function (str) {
      return str
        .replace(/(-.)/g, (m, chr) => chr.toUpperCase())
        .replace(/(^.)/g, (m, chr) => chr.toUpperCase());
    },
    retrieveUserDefaultAddress: function () {
      if (this.$user.loggedIn) {
        this.checkoutData.billing.email = this.$user.email;
        fetch(this.$ZNC_BACKEND_BASE + "/business/v1/customer/address")
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            if (result.success) {
              this.checkoutData.billing = result.address.billing;
              this.checkoutData.billing.email = this.$user.email;
              this.checkoutData.shippingDifferent =
                result.address.shippingDifferent;
              if (result.address.shippingDifferent) {
                this.checkoutData.shipping = result.address.shipping;
              }
            }
          });
      }
    },
    loadProductWithCallback: function (product_num, callback) {
      let isBundle = product_num.startsWith("B:");
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: !isBundle ? product_num : null,
          bundle: isBundle ? product_num.slice(2) : null,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            this.product_unknown = false;
            if (isBundle) {
              callback(result);
            } else {
              if (result.product) {
                callback(result.product.spray);
              } else {
                callback(result);
              }
            }
            this.$EventBus.$emit("gtag", {
              event: "konfigurator-schnellbestellung",
              schritt: "start",
            });
          } else {
            this.product_unknown = true;
          }
        });
    },
    showQuantitySelection: function (product) {
      this.selected_product = { product: product };
      Vue.nextTick(() => {
        this.$bvModal.show("modal-quantityselection");
      });
    },
    showQuantityUpgrade: function (position) {
      this.selected_product = position;
      Vue.nextTick(() => {
        this.$bvModal.show("modal-quantityselection");
      });
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.selected_product.product.indication,
        schritt: "mengenupgrade",
      });
    },
    quantityUpgradeClick: function (quantity) {
      if (this.quantityMode == "quantity-upgrade") {
        this.updatePosQuantity(quantity);
      } else {
        this.checkoutProduct(this.selected_product.product, quantity);
      }
    },
    updatePosQuantity: function (quantity) {
      this.selected_product.count = quantity;
      this.$bvModal.hide("modal-quantityselection");
    },
    checkoutProduct: function (
      product,
      quantity,
      subscription_interval = null
    ) {
      this.$EventBus.$emit(
        "checkout_product",
        product,
        quantity,
        subscription_interval
      );

      this.$bvModal.hide("modal-quantityselection");
      this.$router.replace("/bestellung");
      Vue.nextTick(() => {
        this.paymentPrecheck();
        this.loadCrossSellingProducts();
      });
    },
    checkoutBundle: function (bundle) {
      bundle.products.forEach((product) => {
        this.checkoutProduct(product, 1);
      });
    },
    subscriptionUpgradeClick: function () {
      if (this.quantityMode == "quantity-upgrade") {
        this.$bvModal.hide("modal-quantityselection");
        this.modifySubscription(this.selected_product);
      } else {
        this.showNewSubscription();
      }
    },
    showNewSubscription: function () {
      this.$bvModal.hide("modal-quantityselection");
      this.$bvModal.show("modal-new-subscription");
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: this.selected_product.product.indication,
        schritt: "abonnement",
      });
    },
    modifySubscription: function (position) {
      this.selected_product = position;
      Vue.nextTick(() => {
        this.$bvModal.show("modal-new-subscription");
      });
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        indikation: position.branch,
        schritt: "abonnement",
      });
    },
    updatePositionSubscription: function (quantity, interval) {
      if (this.selected_product.count) {
        let item = this.selected_product;
        item.count = quantity;
        if (item.subscription_interval != undefined) {
          let old_prefix = this.$t("subscriptionProductPrefix", {
            interval: item.subscription_interval,
          });
          let new_prefix = this.$t("subscriptionProductPrefix", {
            interval: interval,
          });
          item.name = item.name.replace(old_prefix, new_prefix);
        } else {
          item.name =
            this.$t("subscriptionProductPrefix", {
              interval: interval,
            }) +
            " " +
            item.name;
        }
        item.subscription_interval = interval;
        this.reload_paypal = true;
        this.resetPaymentMethod();
        Vue.nextTick(() => {
          this.paymentPrecheck();
        });
      } else {
        this.checkoutProduct(this.selected_product.product, quantity, interval);
      }
    },
    cartHasExactlyOneSubscription: function () {
      if (this.$cart.length != 1) {
        return false;
      }
      return this.$cart[0].subscription_interval != undefined;
    },
    cartHasMoreThanOneSubscription: function () {
      if (this.$cart.length <= 1) {
        return false;
      }
      let hasSubscription = false;
      this.$cart.forEach((item) => {
        if (item.subscription_interval != undefined) {
          hasSubscription = true;
        }
      });
      return hasSubscription;
    },
    loadCrossSellingProducts() {
      let prod_list = [];
      this.$cart.forEach((pos) => {
        prod_list.push(pos.id);
      });
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          crossSelling: prod_list,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            this.cross_selling_products = result;
            this.cross_selling_products.forEach((product) => {
              if (!product.details.caption) {
                product.details.caption =
                  product.type == "Spray"
                    ? product.type + " " + product.indication
                    : product.num;
              }
            });
          } else {
            // ignore
          }
        });
    },
    checkoutCSProduct: function (cs_product) {
      fetch(this.$ZNC_BACKEND_BASE + "/business/v1/product", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: cs_product.num,
          lang: this.$i18next.resolvedLanguage,
        }),
      })
        .then((response) => Promise.all([response.status, response.json()]))
        .then(([status, result]) => {
          if (status == 200) {
            if (result.product) {
              this.checkoutProduct(result.product.spray, 1);
            } else {
              this.checkoutProduct(result, 1);
            }
            this.$EventBus.$emit("gtag", {
              event: "cross-selling",
              schritt: "add-to-cart",
            });
          } else {
            // ignore wrong product
          }
        });
    },
    openCSProduct: function (type, product, indication) {
      this.$EventBus.$emit("gtag", {
        event: "cross-selling",
        schritt: "open-product",
      });
      if (type == "Geschenkgutschein") {
        this.$router.push("/geschenkgutschein");
      } else if (type == "Spray") {
        this.$router.push({
          name: indication,
          params: { fromStart: true },
        });
      } else if (product) {
        this.$router.push({
          name: "ProductView",
          params: { product_num: product },
        });
      }
    },
    onAddIndivSpray: function () {
      this.$EventBus.$emit("gtag", {
        event: "konfigurator-checkout",
        schritt: "add-spray",
      });
      this.$router.push("/");
    },
    exitIntentEventListener: function (e) {
      if (e.clientY <= 20) {
        this.showExitIntentPopup();
      }
    },
    showExitIntentPopup: function () {
      if (!this.exitIntentAlreadyShown) {
        let event_info = "";
        if (this.$isMobile) {
          this.$bvModal.show("exit-intent-m");
          event_info = "mobile-checkout";
        } else {
          this.$bvModal.show("exit-intent-d");
          event_info = "desktop-checkout";
        }
        this.exitIntentAlreadyShown = true;
        this.$EventBus.$emit("gtag", {
          event: "konfigurator-checkout",
          schritt: "exit-intent",
          teilschritt: event_info,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.carousel-caption {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @media screen and (min-width: 768px) {
    .img-placeholder {
      padding-left: 180px;
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .cross-selling-text {
    @media screen and (min-width: 768px) {
      text-align: left;
    }
    @media screen and (max-width: 768px) {
      text-align: right;
    }
    & {
      color: var(--zimply-black);
    }

    h3,
    div {
      display: inline;
      @media screen and (max-width: 768px) {
        padding-left: 5px;
        background-color: var(--zimply-beige-t75);
      }
    }
  }
  .cross-selling-buttons {
    text-align: right;
    @media screen and (min-width: 768px) {
      button {
        min-width: 160px;
        margin-bottom: 5px;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 5px;
      button {
        background-color: var(--zimply-beige);
      }
    }
  }
  .cross-selling-price {
    color: var(--zimply-black);
    font-weight: bold;
  }
}
</style>
<style>
.checkout-wrapper {
  padding-bottom: 3rem;
}
.checkout-wrapper table td {
  vertical-align: middle;
}
.cart-table .price-org {
  text-decoration: line-through;
  color: var(--red);
}
.button-table tr:not(:first-child) td {
  border-top: none;
  padding-top: 0;
}
.sumrow td {
  padding-bottom: 0;
}
.sumrow:not(:first-child) td {
  padding-top: 0;
  border-top: none;
}
.sumrow td.number {
  width: 0;
  white-space: nowrap;
  text-align: right;
}
.billing-adress-header,
.shipping-address-header {
  padding-top: 1rem;
}
.adress-switch {
  padding-bottom: 1rem;
}
#checkout-form .form-row + .form-row {
  margin-top: 0.5rem;
}
#checkout-form .row + .row {
  margin-top: 1rem;
}
.address-col {
  padding-left: 0;
  padding-right: 0;
}
.terms {
  padding-left: 15px;
}
.payment-options .btn {
  min-width: 175px;
  min-height: 80px;
  padding: 0;
}
.payment-options .btn {
  display: flex;
}
.payment-options .btn > svg,
.payment-options .btn > div {
  margin: auto;
}
.payment-options .btn .paypal-logo-holder {
  background-color: white;
  border: solid black 1.5px;
  padding: 7px;
  border-radius: 0.25rem;
}
.payment-options.is-invalid label {
  border-color: red;
}
#stripe-container {
  margin: 12px 0px;
  max-width: 500px;
}
#stripe-container .__PrivateStripeElement {
  top: 2px;
}
#stripe-container.StripeElement--focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
#stripe-container.StripeElement--invalid {
  border-color: #dc3545;
}
#stripe-container.StripeElement--focus.StripeElement--invalid {
  box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
}
#paypal-buttons,
#paypal-buttons-express,
#payment-request-button {
  max-width: 340px;
}

@media only screen and (min-width: 768px) {
  .visible-when-small {
    display: none !important;
  }
}
@media only screen and (max-width: 768px) {
  .visible-when-wide {
    display: none !important;
  }
}
@media only screen and (min-width: 576px) {
  .checkout-wrapper th.count,
  .checkout-wrapper td.count {
    text-align: center;
  }
}
@media only screen and (max-width: 576px) {
  .cart-table,
  .button-table {
    margin-bottom: 0;
  }
  .cart-table td {
    text-align: right;
  }
  .cart-table .price-org {
    display: inline-block;
  }
  .button-table tr:first-child td,
  .sumrow:first-child td {
    border-top-width: 3px;
  }
}

.b-toaster {
  z-index: 2200;
}
</style>
